<template>
  <div>
    <el-card>
      <div slot="header" class="flex flex-between flex-v-center">
        <h3>{{ $t('常见问题') }}</h3>
      </div>


      <el-collapse v-model="activeName" accordion>
        <el-collapse-item name="1">

          <template slot="title">
            <h3>● {{ $t('如何处理无法登录的问题？') }}</h3>
          </template>
          <div class="st1 margin-x">{{ $t('检查网络是否正常；刷新一下网页，然后重新扫码。') }}</div>
        </el-collapse-item>
        <el-collapse-item name="2">

          <template slot="title">
            <h3>● {{ $t('出现无法录音的情况如何处理？') }}</h3>
          </template>
          <div class="st1 margin-x">
            {{ $t('请检查麦克风设备。如是您是第一次使用本网站，页面地址栏处会弹出使用您的麦克风，请选择允许。如拒绝，会出现无法录音的情况。如已拒绝，需将本网页麦克风权限更改为允许，然后重新加载页面即可。') }}
          </div>
          <div><el-image style="width:400px" fit="contain"
              :src="$store.state.lang == 'en' ? '/web/image/faq/1en.png' : '/web/image/faq/1.png'"></el-image></div>
          <div class="margin-lt"><el-image style="height:300px" fit="contain"
              :src="$store.state.lang == 'en' ? '/web/image/faq/2en.png' : '/web/image/faq/2.png'"></el-image>
          </div>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title">
            <h3>● {{ $t('VIP到期后会自动续费吗？') }}</h3>
          </template>
          <div class="st1 margin-x">{{ $t('VIP 项目为一次性付费，到期后不会自动续费，需要手动续费。') }}</div>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template slot="title">
            <h3>● {{ $t('VIP已经充值但是在网站不显示') }}</h3>
          </template>
          <div class="st1 margin-x">{{ $t('请刷新页面，或者退出账号后重新登录。') }}</div>
        </el-collapse-item>

        <el-collapse-item name="5">
          <template slot="title">
            <h3>● {{ $t('录音有杂音怎么解决？') }}</h3>
          </template>
          <div class="st1 margin-x">1、{{ $t('确认麦克风是否自带杂音。') }}</div>
          <div class="st1 margin-x">2、{{ $t('确认录音环境是否安静。') }}</div>
          <div class="st1 margin-x">3、{{ $t('电脑软件可能打开过多，关闭不常用软件，然后重启浏览器或重启电脑。') }}</div>
        </el-collapse-item>


        <!-- <el-collapse-item title="● 评分错误代码参考" name="6">
          <template slot="title">
            <h3>● 评分错误代码参考</h3>
          </template>
          <div class="st1 margin-x">1、确认麦克风是否自带杂音。</div>
          <div class="st1 margin-x">2、确认录音环境是否安静。</div>
          <div class="st1 margin-x">3、电脑软件可能打开过多，关闭不常用软件，然后重启浏览器或重启电脑。</div>
        </el-collapse-item> -->


        <el-collapse-item name="7">
          <template slot="title">
            <h3>● {{ $t('如何下载机经/答题模版？') }}</h3>
          </template>
          <div class="st1 margin-x">{{ $t('请添加海澳英语小编微信：ptefighter，或扫描小编微信号领取模板。') }}</div>
          <div><el-image style="height:300px" fit="contain" src="/web/image/home/kefu.jpeg"></el-image></div>
        </el-collapse-item>


        <el-collapse-item name="8">
          <template slot="title">
            <h3>● {{ $t('未能解决您的问题？') }}</h3>
          </template>
          <div class="st1 margin-x">{{ $t('如果常见问题中的解决方案没有解决您的问题，请添加海澳英语小编微信：ptefighter，或扫描小编微信号进行反馈，我们会尽快解决您的问题。') }}
          </div>
          <div><el-image style="height:300px" fit="contain" src="/web/image/home/kefu.jpeg"></el-image></div>
        </el-collapse-item>
      </el-collapse>

    </el-card>

  </div>
</template>
<script>
export default {
  data() {
    return {
      tab1: 1,
      tab2: 1,
      date: "",
      tableData: [{
        col1: this.$t('口语AI评分'),
        col2: this.$t('每日两次'),
        col3: this.$t('无限练习')
      }, {
        col1: this.$t('写作AI评分'),
        col2: this.$t('每日两次'),
        col3: this.$t('无限练习')
      }, {
        col1: this.$t('听力AI评分'),
        col2: this.$t('每日两次'),
        col3: this.$t('无限练习')
      }, {
        col1: this.$t('PTE纠音'),
        col2: this.$t('免费3个'),
        col3: this.$t('无限练习')
      }, {
        col1: this.$t('训练营'),
        col2: this.$t('开通三个免费练习'),
        col3: this.$t('无限练习')
      }, {
        col1: this.$t('PTE模考'),
        col2: this.$t('非真题模考'),
        col3: this.$t('无限制真题模考')
      },],
      activeName: ""
    };
  },

  created() { },

  methods: {
    to() {
      this.$router.push("//main/practise")
    },

    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column)
      if (columnIndex === 1) {
        if (rowIndex === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else if (rowIndex === 1 || rowIndex === 2) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
  },
};
</script>

<style scoped>
.header {
  padding: 0 28px;
  background: rgb(67, 69, 82);
  border-radius: 10px;
  color: rgb(233, 207, 164);

}

.price {
  font-size: 30px;
  font-weight: 700;
  color: rgb(251, 158, 97);
  text-align: center;
}

.checked .price {
  color: #fff;
}

.checked {
  background: rgb(233, 207, 164) !important;
  color: #fff;
}

.pay {
  width: 200px;
  background: rgb(233, 207, 164);
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
}

.checked1 {
  border-color: rgb(233, 207, 164);
}


.checked .st {
  color: #fff;
}

#table>>>th {
  text-align: center;
}

#table>>>.cell {
  text-align: center;
}
</style>
